import { AvailableApiName } from '@environments/assets/available-api-name.type';
import { EnvironmentInterface } from '@environments/assets/environment.interface';
import { cmsApiTargets } from '@config/apis/cms/cms-api-targets';
import { idApiTargets } from '@config/apis/id/id-api-targets';
import { membershipApiTargets } from '@config/apis/membership/membership-api-targets';
import { proxyApiTargets } from '@config/apis/proxy/proxy-api-targets';
import { agreementApiTargets } from '@config/apis/agreement/agreements-api-targets';
import { notificationApiTargets } from '@config/apis/notification/notification-api-targets';
import { EnvironmentType } from '@environments/assets/environment-type';

export const environment = {
  apis: {
    agreement: agreementApiTargets.staging,
    cms: cmsApiTargets.staging,
    id: idApiTargets.staging,
    membership: membershipApiTargets.staging,
    notification: notificationApiTargets.staging,
    proxy: proxyApiTargets.staging,
  } satisfies Record<AvailableApiName, EnvironmentInterface>,
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
  },
  frontend: {
    baseUrl: 'https://minside.fagforbundet.build',
    redirectUrl: 'https://minside.fagforbundet.build/login',
    postLogoutRedirectUri: 'https://minside.fagforbundet.build',
    shopStewardWebUrl: 'https://tillitsvalgt.fagforbundet.build',
    verifyEmailAddressUrl: 'https://id.fagforbundet.dev/verification/email',
  },
  loadingScreen: {
    debug: false,
  },
  oidc: {
    debug: false,
  },
  pageTitlePrefix: 'Min Side',
  requestBundle: {
    ttl: 60000,
  },
  router: {
    debug: false,
  },
  type: EnvironmentType.BUILD,
  useMocks: false,
};
